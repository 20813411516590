import { postAnalyticsUserIdentificationEvent } from './analytics';
import { client } from './api';
import { FeatureKey, setAccessList } from './feature-manager';
import makeId from './local-id.mjs';
import { useNavigationStore } from './store/navigation';
import { useProjectStore } from './store/project';
import { User, UserWithSession } from './user';
import { getDeviceName } from './utils';
import localForage from 'localforage';
import { useToastStore } from './store/toasts';
import { Tenant } from './store/tenant';
import axios from 'axios';
import useIsGather from './composables/useIsGather';
import { Project } from './project';

export type LoginResponse = {
  token: string;
  user: UserWithSession;
  access_list: FeatureKey[];
  impersonator: User | null;
  tenant: Tenant;
  project?: Project;
};

class Auth {
  private loginDataKey = 'auth_user' as const;
  private legacyUserKey = 'user' as const;
  private sanctumCookie = 'XSRF-TOKEN' as const;

  private _user: UserWithSession | null = null;
  private _token: string | null = null;
  private _impersonator: User | null = null;

  constructor() {
    this.parseUserFromStorage();
    if (this._token) {
      this.fetch();
    }
  }

  private parseUserFromStorage(): UserWithSession | null {
    // When called by hub/services via Node/Bun / services vitest
    if (typeof window === 'undefined') {
      return null;
    }
    const storedUser = localStorage.getItem(this.loginDataKey);
    if (storedUser) {
      try {
        const data: LoginResponse = JSON.parse(storedUser);
        this.parseLoginResponse(data);
      } catch (error) {
        console.error('Failed to parse user from localStorage', error);
        this.clearUser();
      }
    }
    return null;
  }

  getToken(): string | null {
    return this._token;
  }
  token(): string | null {
    return this.getToken();
  }

  private setToken(token: string | null) {
    this._token = token;

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
      delete client.defaults.headers.common['Authorization'];
    }

    // Store the token in localForage for use in serviceWorker
    localForage.setItem('auth_token', token);
  }

  private setResponseData(loginResponse: LoginResponse) {
    this._user = loginResponse.user;
    this._impersonator = loginResponse.impersonator || null;
    localStorage.setItem(this.loginDataKey, JSON.stringify(loginResponse));
    localStorage.setItem(
      this.legacyUserKey,
      JSON.stringify(loginResponse.user)
    );
  }

  private clearUser() {
    console.trace('clearing user');
    this._user = null;
    this._impersonator = null;
    localStorage.removeItem(this.loginDataKey);
    localStorage.removeItem(this.legacyUserKey);
    this.setToken(null);
  }

  async login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.getXsrfToken() || '',
      },
      body: JSON.stringify({ email, password, remember_me: rememberMe }),
    });

    if (!response.ok) {
      throw new AuthRequestError('Login failed', { status: response.status, data: await response.json() });
    }

    const data = await response.json();
    this.parseLoginResponse(data);
    return data;
  }

  private async makeCsrfRequest(): Promise<string> {
    console.debug('Making CSRF request');
    const url = new URL(window.location.href);
    url.search = '';
    url.hash = '';
    url.pathname = '/sanctum/csrf-cookie';
    console.debug('Cookie header before', document.cookie);
    const response = await axios.get(url.toString());
    const cookieHeader = response.headers['set-cookie'];
    console.debug(
      'Cookie header after',
      document.cookie,
      'set-cookie',
      cookieHeader,
      'headers',
      response.headers
    );

    const xsrf = this.getXsrfToken();
    console.debug('Got XSRF token', xsrf);
    if (!xsrf) {
      throw new Error('Failed to get XSRF token');
    }
    return xsrf;
  }

  async loginWithHandoverToken(
    token: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    let xsrf = this.getXsrfToken() || '';
    if (xsrf === '') {
      console.warn('No XSRF token found, fetching one');
      xsrf = await this.makeCsrfRequest();
    }

    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': xsrf,
      },
      body: JSON.stringify({ handover_token: token, remember_me: rememberMe }),
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();

    // Ensure impersonation state is not lost when navigating back via handover
    // if the user matches
    if (data.user?.user_id === this._user?.user_id && this._impersonator) {
      data.impersonator ||= this._impersonator;
    }

    this.parseLoginResponse(data);
    return data;
  }

  async loginViaAccessToken(token: string): Promise<LoginResponse> {
    const response = await fetch('/api/auth/user', {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-TOKEN': this.getXsrfToken() || '',
      },
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();
    this.parseLoginResponse(data);
    return data;
  }

  async loginViaLabToken(
    token: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.getXsrfToken() || '',
      },
      body: JSON.stringify({ lab_token: token, remember_me: rememberMe }),
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();
    this.parseLoginResponse(data);
    return data;
  }

  private parseLoginResponse(data: LoginResponse): void {
    const user = data.user;

    if (!user) {
      throw new Error('No user data returned in parse user data');
    }
    if (!data.token) {
      throw new Error('No token returned in parse user data');
    }
    this.setToken(data.token);
    this.setResponseData(data);

    setAccessList(data.access_list || [], user.user_id);

    localStorage.setItem('tenant', JSON.stringify(data.tenant));
    localStorage.setItem('user', JSON.stringify(user));
    if (useIsGather()) {
      localStorage.setItem('gather-user-' + user.enad_ref, makeId());
    } else if (!localStorage.getItem('hub-user-' + user.enad_ref)) {
      localStorage.setItem('hub-user-' + user.enad_ref, makeId());
    }
    localForage.setItem('device', getDeviceName());

    try {
      postAnalyticsUserIdentificationEvent({
        email: user.email,
        enad_ref: user.enad_ref,
        user_name: user.name || '',
        customer_ref: user.customer_ref,
        company_name: user.company?.company_name,
      });
    } catch (e) {
      console.error('Failed to send user identification event', e);
    }
  }

  async logout(): Promise<void> {
    if (this.impersonating()) {
      await this.unimpersonate();
    }

    const token = this.getToken();
    this.clearUser();
    if (!token) {
      console.error('Unexpected missing token during logout');
      return;
    }

    const response = await fetch('/api/auth/logout', {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': this.getXsrfToken() || '',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      console.error('Failed to logout', response.status, await response.text());
    }
  }

  clear() {
    this.clearUser();
  }

  async startImpersonation(userId: number, redirect?: string): Promise<void> {
    const navigationStore = useNavigationStore();
    const projectStore = useProjectStore();
    projectStore.resetProject();
    navigationStore.disableDemoMode();

    try {
      const response = await fetch(`/api/auth/impersonate/${userId}`, {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': this.getXsrfToken() || '',
          Accept: 'application/json',
          Authorization: `Bearer ${this.getToken()}`,
        },
      });

      if (!response.ok) {
        console.error(
          'Impersonation failed',
          response.status,
          await response.text()
        );
        throw new Error('Impersonation failed');
      }

      const data = await response.json();
      this.setToken(data.token);
      this.setResponseData(data);
    } catch (e: any) {
      if (e.response?.status === 422 && 'tenancy_url' in e.response.data) {
        useToastStore().warning(
          'This user belongs to ' +
          e.response.data.tenancy_url +
          '. You will be redirected to that tenancy in a moment. Please request impersonation again.'
        );
        setTimeout(() => {
          window.open(e.response.data.tenancy_url, '_blank');
        }, 1000);
      }
      throw e;
    }

    if (redirect) {
      window.location.href = redirect;
      return;
    }
    window.location.reload();
  }
  impersonate(userId: number, redirect?: string): Promise<void> {
    return this.startImpersonation(userId, redirect);
  }

  impersonating(): boolean {
    return !!this._impersonator;
  }

  impersonator(): User | null {
    return this._impersonator;
  }

  async leaveImpersonation(redirect?: string): Promise<void> {
    try {
      const response = await fetch('/api/auth/impersonate/leave', {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': this.getXsrfToken() || '',
          Authorization: `Bearer ${this.getToken()}`,
        },
        body: JSON.stringify({
          remember_me: localStorage.getItem('remember_me') === 'true',
        }),
      });
      if (!response.ok) {
        console.error(
          'Failed to leave impersonation',
          response.status,
          await response.text()
        );
        throw new Error('Failed to leave impersonation');
      }

      const data = (await response.json()) as LoginResponse;
      this.setToken(data.token);
      this.setResponseData(data);
      window.location.href = redirect || '/';
    } catch (e: any) {
      this.clearUser();
      console.error('Failed to send leave impersonation request', e);
      useToastStore().unexpected(e);
      throw e;
    }
  }
  unimpersonate(redirect?: string): Promise<void> {
    return this.leaveImpersonation(redirect);
  }

  getUser(): UserWithSession | null {
    return this._user;
  }

  setUser(user: UserWithSession) {
    this._user = user;
  }

  /**
   * This is for convenience to avoid null checks in authenticated components
   */
  user(): UserWithSession {
    if (!this._user) {
      console.error('auth.user() called without session, NPE incoming');
    }
    return this._user!; // prevents the need to refactor every component to handle null
  }

  async fetch(): Promise<UserWithSession> {
    const response = await fetch('/api/auth/user', {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        'X-CSRF-TOKEN': this.getXsrfToken() || '',
      },
    });

    const user = await response.json();
    this.setResponseData(user);
    return user;
  }

  ready(): boolean {
    return true;
  }

  isLoggedIn(): boolean {
    return !!this._user;
  }
  check(): boolean {
    return this.isLoggedIn();
  }

  private getXsrfToken(): string | undefined {
    const token = document.cookie
      .split('; ')
      .find((row) => row.startsWith(this.sanctumCookie))
      ?.split('=')[1];
    if (!token) {
      console.warn('No XSRF token found');
    }
    return token;
  }
}

export class AuthRequestError extends Error {
  constructor(message: string, public response: { status: number, data: any }) {
    super(message);
  }
}

const auth = new Auth();

export default auth;
