<script setup lang="ts">
import _debounce from 'lodash/debounce';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import {
  checkIsInputValueEmpty,
  checkIsInputValueValueEmpty,
} from '../../business-logic/input-value';
import EventBus from '../../EventBus';
import type { GatherField, InputValue } from '../../gather';
import { fieldHasTolerance, inputValuePasses } from '../../utils/tolerance';
import { safeMultiply } from '../../utils/unit-conversion';
import { waitFor } from '../../utils/wait-for';
import Hazard from '../classes/Hazard.js';

const props = withDefaults(
  defineProps<{
    field: GatherField;
    inputValue: InputValue;
    sectionIndex: number;
    isSafety?: boolean;
    lastValue?: object;
    inputValues: InputValue[];
  }>(),
  {
    isSafety: false,
  }
);

const formContext = inject('formContext') as any;
const isDefaultInputValue = ref(false);
const isWaiting = ref(false);
const numberInput = ref<HTMLInputElement | null>(null);
// The typedValue records the data that users have typed and
// is used to fix the bug that only 234 appears after 1234
// is typed in a repeating section.
// Not sure why form/fields/TextField doesn't need this.
const typedValue = ref<string | null>(null);

const disabled = computed(() => {
  return (
    props.field.options?.is_readonly || props.inputValue?.options?.disabled
  );
});

const value = computed({
  get() {
    if (typedValue.value !== null) {
      return typedValue.value;
    }

    if (checkIsInputValueEmpty(props.inputValue, props.field)) {
      return null;
    }

    const _value = parseFloat(props.inputValue.value! as string);
    return safeMultiply(_value, 1);
  },
  set(value: string) {
    typedValue.value = value;

    if (!isWaiting.value) {
      isWaiting.value = true;
    }
    setValueDebounced(value);
  },
});

const hasTolerance = computed(() => {
  return fieldHasTolerance(props.field);
});

const passesTolerance = computed(() => {
  if (!hasTolerance.value) {
    return false;
  }
  return inputValuePasses(props.field, props.inputValue, props.inputValues);
});

function getDefaultValue() {
  const { default_value: startingValue, increment } = {
    default_value: undefined,
    increment: undefined,
    ...props.field.options,
  };
  if (startingValue === undefined && increment === undefined) {
    return null;
  }

  const { value: _lastValue } = { value: undefined, ...props.lastValue };
  if (_lastValue !== undefined && increment !== undefined) {
    return (
      Math.round((parseFloat(_lastValue) + parseFloat(increment)) * 1000000) /
      1000000
    );
  }

  if (startingValue !== undefined) {
    return Math.round(parseFloat(startingValue) * 1000000) / 1000000;
  }

  return null;
}

function setValue(value) {
  const _isDefaultInputValue = isDefaultInputValue.value;
  if (_isDefaultInputValue) {
    isDefaultInputValue.value = false;
  }
  EventBus.$emit('updateInputValue', {
    inputValue: { ...props.inputValue, value },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
    isDefaultInputValue: _isDefaultInputValue,
  });
}

function getStyle(value) {
  if (value === null || !props.inputValue?.options?.is_health_safety) {
    return null;
  }
  const hazard = new Hazard({});
  return hazard.calculateStyling(value);
}

async function handleBlur() {
  if (isWaiting.value) {
    await waitFor(() => !isWaiting.value);
    await nextTick();
  }
  if (checkIsInputValueEmpty(props.inputValue, props.field)) {
    isDefaultInputValue.value = true;
    const defaultValue = getDefaultValue();
    setValue(defaultValue);
  }
}

watch(isWaiting, (newValue) => {
  formContext.setIsBusy(newValue);
});

onMounted(() => {
  if (props.field.options?.increment) {
    numberInput.value!.step = props.field.options.increment;
  }

  if (checkIsInputValueEmpty(props.inputValue, props.field)) {
    isDefaultInputValue.value = true;
    const defaultValue = getDefaultValue();
    setValue(defaultValue);
  }
});

const setValueDebounced = _debounce((value) => {
  setValue(value);
  if (isWaiting.value) {
    isWaiting.value = false;
  }
  typedValue.value = null;
}, 1000);
</script>

<template>
  <div class="form-group" :class="getStyle(value)">
    <label class="form-label" for="number">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
    </label>
    <div class="input-group">
      <span v-if="field.options?.prefix" class="input-group-text">
        {{ field.options.prefix }}
      </span>
      <input
        ref="numberInput"
        v-model="value"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        step="any"
        :disabled="disabled"
        :class="[
          'form-control',
          {
            'border-danger':
              field.is_required && checkIsInputValueValueEmpty(value),
          },
        ]"
        name="number"
        @blur="handleBlur"
        @wheel.prevent
      />
      <span v-if="field.options?.unit" class="input-group-text">
        {{ field.options.unit }}
      </span>
      <span
        v-if="hasTolerance"
        :class="[
          'input-group-text',
          {
            'bg-success': passesTolerance,
            'bg-warning text-light': !passesTolerance,
          },
        ]"
      >
        <i
          :class="[
            'fas fa-fw',
            {
              'fa-check-circle': passesTolerance,
              'fa-times-circle': !passesTolerance,
            },
          ]"
        ></i>
      </span>
    </div>
  </div>
</template>
