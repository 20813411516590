<script lang="ts" setup>
import axios from 'axios';
import { computed, inject, onMounted, ref } from 'vue';
import {
  FORM_CONTEXT_TYPE_DATA_MANAGER,
  FORM_CONTEXT_TYPE_APP_PREVIEW,
  FORM_CONTEXT_TYPE_GATHER,
  FORM_CONTEXT_TYPE_PUBLIC_FORM,
} from '../../business-model/common';
import { GatherField, InputValue, Item, App } from '../../gather';
import EventBus from '../../EventBus';
import { usePublicFormToken } from '../../composables/usePublicFormToken';

const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
  app: App;
}>();

const formContext = inject<{
  type: number;
  getLinkedItemId: (appTitle: string) => number | undefined;
} | null>('formContext', null);
const sampleModal = inject<any>('sampleModal', null);

const options = ref<Item[]>([]);
const isLoaded = ref(false);
const notConfigured = ref(false);
const defaultValue = computed<number | null>(() => {
  const appTitle = props.field.options?.template_tab_title;
  return appTitle ? formContext?.getLinkedItemId(appTitle) ?? null : null;
});

const value = computed({
  get() {
    if (props.inputValue.value) {
      return props.inputValue.value;
    }

    if (defaultValue.value) {
      EventBus.$emit('updateInputValue', {
        inputValue: { ...props.inputValue, value: defaultValue.value },
        field: props.inputValue.template_field_id,
        sectionIndex: props.inputValue.template_section_index,
        templateTabId: props.inputValue.template_tab_id,
        isDefaultInputValue: true,
      });
    }
    return defaultValue.value;
  },
  set(updated) {
    EventBus.$emit('updateInputValue', {
      inputValue: {
        ...props.inputValue,
        value: updated,
        reference: options.value.find((item) => item.id === updated),
      },
      field: props.inputValue.template_field_id,
      sectionIndex: props.inputValue.template_section_index,
      templateTabId: props.inputValue.template_tab_id,
    });
  },
});

const selectedOption = computed(() => {
  if (!value.value) {
    return null;
  }
  return options.value.find((o) => String(o.id) === String(value.value));
});

function gotoLinkedItem() {
  if (!selectedOption.value) {
    throw new Error('gotoLinkedItem: No option selected');
  }
  if (!sampleModal) {
    throw new Error('gotoLinkedItem: No sample modal');
  }
  sampleModal.gotoItem(selectedOption.value);
}

onMounted(async () => {
  if (!props.field.options?.template_tab_title) {
    notConfigured.value = true;
    return;
  }

  isLoaded.value = false;
  const token = usePublicFormToken();
  try {
    const apiUrl = getApiUrl(props.app.project_id);
    const {
      data: { items },
    } = await axios.post(apiUrl, {
      project_id: props.app.project_id,
      appTitle: props.field.options.template_tab_title,
      token,
    });
    options.value = items;
  } catch (e) {
    notConfigured.value = true;
    throw e;
  } finally {
    isLoaded.value = true;
    // The value.value's getter accessor includes logic to get the default value.
    // So instead of calling the setter accessor we should use the getter accessor.
    value.value;
  }
});

function getApiUrl(projectId: number) {
  if (
    [
      FORM_CONTEXT_TYPE_GATHER,
      FORM_CONTEXT_TYPE_PUBLIC_FORM,
      FORM_CONTEXT_TYPE_APP_PREVIEW,
    ].includes(formContext?.type ?? -1)
  ) {
    return '/api/project/items-from-app-title?project_id=' + projectId;
  } else if (formContext?.type === FORM_CONTEXT_TYPE_DATA_MANAGER) {
    return '/project/items-from-app-title?project_id=' + projectId;
  } else {
    throw 'Invalid form context';
  }
}
</script>

<template>
  <div class="form-group">
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
    </label>

    <div class="input-group d-flex">
      <select
        v-model="value"
        class="form-control"
        :class="{
          'border-danger': field.is_required && (!value || value == ''),
        }"
        style="height: 2.3rem"
        name="dropdown"
        :required="!!field.is_required"
      >
        <option v-if="!defaultValue" :value="null">Not selected.</option>
        <option v-for="option of options" :key="option.id" :value="option.id">
          {{ option.custom_title }}
        </option>
      </select>
      <button
        v-if="selectedOption && sampleModal"
        class="btn btn-outline-primary"
        :disabled="sampleModal.hasChangedInputValues"
        @click.stop="gotoLinkedItem"
      >
        <i class="fal fa-pencil-alt" />
      </button>
    </div>

    <div v-if="notConfigured" class="alert alert-warning p-2 mt-2 mb-0">
      This field is not linked to another app, please update it in the App
      Editor.
    </div>
    <div
      v-else-if="isLoaded && options.length == 0"
      class="alert p-2 mt-2 mb-0"
      :class="{
        'alert-danger': field.is_required,
        'alert-warning': !field.is_required,
      }"
    >
      You {{ field.is_required ? '' : 'may' }} need to add a
      {{
        field.options?.template_tab_title
          ? field.options.template_tab_title
          : field.label
      }}
      first.
    </div>
  </div>
</template>
