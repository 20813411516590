import _upperFirst from 'lodash/upperFirst.js';
import { FieldTypeId, getFieldTypeById } from '../../fields';
import CaptionField from './Caption.vue';
import CheckboxField from './Checkbox.vue';
import CopyDataLinkField from './CopyDataLink.vue';
import DateField from './Date.vue';
import DepthField from './Depth.vue';
import DocumentField from './Document.vue';
import DrawingField from './Drawing.vue';
import DropdownField from './Dropdown.vue';
import ExpressionField from './Expression.vue';
import DuplicateField from './LabId.vue';
import LithologyField from './Lithology.vue';
import MediaField from './Media.vue';
import NumberField from './Number.vue';
import ReferenceField from './Reference.vue';
import SignatureField from './Signature.vue';
import TextField from './TextField.vue';
import AddressField from './Address.vue';
import { default as LabIdField, default as TriplicateField } from './LabId.vue';
import UnknownField from './Unknown.vue';
import UserField from './User.vue';

export const types = [
  'checkbox',
  'text',
  'number',
  'date',
  'dropdown',
  'depth',
  'media',
  'reference',
  'signature',
  'caption',
  'lithology',
  'expression',
  'document',
  'duplicate',
  'triplicate',
  'drawing',
  'address',
  'user',
  'lab-id',
  'copy-data-link',
];

function pascalCase(str: string) {
  return str
    .split('-')
    .map((word) => _upperFirst(word))
    .join('');
}

export function getComponentName(type: string) {
  if (types.includes(type)) {
    return pascalCase(type + '-field');
  }

  return 'UnknownField';
}

export function getComponentNameFromId(field_type_id: FieldTypeId) {
  const fieldType = getFieldTypeById(field_type_id);
  if (!fieldType) {
    throw new Error(`Field type with id ${field_type_id} not found`);
  }
  return getComponentName(fieldType.type);
}

export function getUpperCaseComponentNameFromId(field_type_id: FieldTypeId) {
  const fieldName = getComponentNameFromId(field_type_id);
  return fieldName
    .split('-')
    .map((word) => _upperFirst(word))
    .join('');
}

export const components = {
  UnknownField,
  CheckboxField,
  TextField,
  NumberField,
  DateField,
  DropdownField,
  DepthField,
  MediaField,
  ReferenceField,
  SignatureField,
  CaptionField,
  LithologyField,
  ExpressionField,
  DocumentField,
  DuplicateField,
  TriplicateField,
  DrawingField,
  AddressField,
  UserField,
  LabIdField,
  CopyDataLinkField,
} as const;
