<template>
  <div>
    <label class="form-label" for="dropdown">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
      <i class="fas fa-eraser clickable" @click="clearSignature" />
    </label>
    <div class="position-relative">
      <canvas
        v-if="isGather"
        ref="signatureCanvas"
        width="280px"
        height="100px"
        style="border: 1px solid #ced4da; border-radius: 0.25rem"
        :class="{ 'border-danger': field.is_required && !inputValue.value }"
      />
      <template v-else>
        <img v-if="placeholderImage" class="w-100" :src="placeholderImage" />
        <div class="alert alert-warning mb-0">
          <i class="fas fa-exclamation-triangle"></i>
          <span class="ms-2"> Please edit signatures on Gather.</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useIsGather from '../../composables/useIsGather';
import EventBus from '../../EventBus';
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useProjectStore } from '../../store/project';
import { InputValue, GatherField } from '../../gather';
import type ISignaturePad from 'signature_pad';

const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
}>();
const projectStore = useProjectStore();
const signaturePadLib = ref<typeof ISignaturePad>();
const signaturePad = ref<ISignaturePad>();

const signatureCanvas = ref<HTMLCanvasElement>();

const project = computed(() => {
  if (!projectStore.project) {
    throw new Error('Project is not defined');
  }
  return projectStore.project!;
});
const isGather = computed(() => useIsGather());
const placeholderImage = computed(() => {
  const value = props.inputValue.value as string;

  if (!value) return;
  if (typeof value !== 'string') {
    console.warn('Signature value is not a string');
  }

  if (value.includes('base64')) {
    return value;
  }

  return '/api/images/value/' + project.value.project_id + '/' + value;
});

function clearSignature() {
  signaturePad.value?.clear();

  EventBus.$emit('updateInputValue', {
    inputValue: {
      ...props.inputValue,
      value: null,
    },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
}

async function initializeSignature() {
  if (!signaturePadLib.value) {
    signaturePadLib.value = (await import('signature_pad')).default;
  }

  if (!signatureCanvas.value) {
    throw new Error('Signature canvas is not defined');
  }
  signaturePad.value = new signaturePadLib.value(signatureCanvas.value, {
    penColor: 'rgb(0, 0, 0)',
  });
  signaturePad.value.addEventListener('endStroke', finishSignatureStroke);
  signaturePad.value.on();
  resizeCanvas();
}
function resizeCanvas() {
  if (!signatureCanvas.value || !signaturePad.value) {
    throw new Error('Signature canvas is not defined');
  }
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  signatureCanvas.value.width = signatureCanvas.value.offsetWidth * ratio;
  signatureCanvas.value.height = signatureCanvas.value.offsetHeight * ratio;
  signatureCanvas.value.getContext('2d')!.scale(1, 1);
  signaturePad.value.clear();
  nextTick(() => {
    if (!signaturePad.value || !signatureCanvas.value) {
      throw new Error('Signature Pad is not initialized');
    }
    if (props.inputValue.value && typeof props.inputValue.value === 'string') {
      signaturePad.value.fromDataURL(props.inputValue.value, {
        ratio,
        width: signatureCanvas.value.offsetWidth,
        height: signatureCanvas.value.offsetHeight,
      });
    }
  });
}

onMounted(() => {
  if (isGather.value) {
    initializeSignature();
  }
});
onBeforeUnmount(() => {
  signaturePad.value?.off();
});

function finishSignatureStroke() {
  if (!signaturePad.value) {
    throw new Error('Signature Pad is not initialized');
  }
  const dataUrl = signaturePad.value.toDataURL();
  EventBus.$emit('updateInputValue', {
    inputValue: {
      ...props.inputValue,
      value: dataUrl,
    },
    field: props.inputValue.template_field_id,
    sectionIndex: props.inputValue.template_section_index,
    templateTabId: props.inputValue.template_tab_id,
  });
}
</script>
