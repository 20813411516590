import { produce } from 'immer';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';
import moment from 'moment';
import { RESULT_TYPE_BOOLEAN } from '../business-model/expression';
import { FieldTypeIds } from '../fields';
import type {
  App,
  GatherField,
  GatherFieldOptions,
  InputValue as GatherInputValue,
} from '../gather';
import { Address } from '../project';

export interface InputValue {
  id: number;
  /// ...
  value: InputValueValue;
  value2: InputValueValue;
}

export type InputValueValue =
  | string
  | null
  | boolean
  | number
  | string[]
  | Address
  | object;
export type ValuePair = {
  value: InputValueValue;
  value2: InputValueValue;
};

export type CompositeKey = {
  sample_id?: number;
  template_tab_id: number;
  template_section_id: number;
  template_section_index: number;
  template_field_id: number;
};

export function checkIsInputValueUnset(inputValue: InputValue | null) {
  if (!inputValue) {
    return true;
  }

  const { value, value2 } = inputValue;
  return _isNil(value) && _isNil(value2);
}

export function isYesBooleanValue(value: InputValueValue) {
  return (
    value === true || value === 'true' || value === 'Yes' || value === 'yes'
  );
}

export function isNoBooleanValue(value: InputValueValue) {
  return (
    value === false || value === 'false' || value === 'No' || value === 'no'
  );
}

export function checkIsInputValueValueEmpty(value: InputValueValue) {
  if (typeof value === 'string') {
    return value.trim() === '';
  } else if (_isNil(value)) {
    return true;
  }

  return false;
}

export function checkIsInputValueEmpty(
  inputValue: InputValue,
  field: GatherField
) {
  return checkIsValuePairEmpty(inputValue, field);
}

export function checkIsValuePairEmpty(
  valuePair: ValuePair,
  field: GatherField
) {
  const { value, value2 } = valuePair;
  const isValueEmpty = checkIsInputValueValueEmpty(value);
  const isValue2Empty = checkIsInputValueValueEmpty(value2);

  const isDepthField = field.field_type_id === FieldTypeIds.DEPTH;
  if (isDepthField) {
    return (
      isValueEmpty || ((field.options?.is_range && isValue2Empty) ?? false)
    );
  }

  return isValueEmpty && isValue2Empty;
}

export function makeCaptionLinksTargetBlank(caption: string): string {
  const pattern = /<a([^>]+)>/gi;
  return caption.replaceAll(pattern, '<a$1 target="_blank">');
}

export function formatInputValueAsItemTitle(
  inputValue: InputValue,
  field: GatherField
): string {
  const { value, value2 } = inputValue;
  switch (field.field_type_id) {
    case FieldTypeIds.TEXT:
      return ((value ?? '') as string).trim();
    case FieldTypeIds.NUMBER:
      const { prefix, unit } = field.options as GatherFieldOptions;
      return !Number.isNaN(parseFloat(String(value)))
        ? `${prefix ?? ''} ${value} ${unit ?? ''}`.trim()
        : '';
    case FieldTypeIds.DATE:
      let { format } = field.options as GatherFieldOptions;
      if (_isNil(format) || format === 'day') {
        format = 'DD-MM-YYYY';
      } else if (format === 'month') {
        format = 'MM-YYYY';
      } else if (format === 'year') {
        format = 'YYYY';
      }
      return value
        ? `${moment
            .utc(value as string)
            .local()
            .format(format)} ${value2 ?? ''}`.trim()
        : '';
    case FieldTypeIds.DROPDOWN:
      const { has_multiple } = field.options as GatherFieldOptions;
      return !has_multiple
        ? ((value ?? '') as string)
        : (value as string[]).join(', ');
    case FieldTypeIds.CHECKBOX:
      return value === 'yes' ? 'Yes' : 'No';
    case FieldTypeIds.DEPTH:
      let { unit: depthUnit, is_range: isRange } =
        field.options as GatherFieldOptions;
      if (!depthUnit) {
        depthUnit = 'm';
      }

      if (_isNil(value)) {
        return '';
      }

      if (!isRange || _isNil(value2)) {
        return `${value}${depthUnit}`;
      }

      return `${value}${depthUnit} - ${value2}${depthUnit}`;
    case FieldTypeIds.LITHOLOGY:
      return (value ?? '') as string;
    case FieldTypeIds.EXPRESSION:
      const options = field.options as GatherFieldOptions;
      return options.expression?.resultType === RESULT_TYPE_BOOLEAN
        ? (value as boolean)
          ? 'Yes'
          : 'No'
        : ((value ?? '') as string);
  }

  return '';
}

export const processInputResponse = (response) => {
  return response.data.input_values.map((iv) => {
    if (!iv.value) return iv;
    if (!iv.value.startsWith('{') && !iv.value.startsWith('[')) {
      return iv;
    }
    try {
      iv.value = JSON.parse(iv.value);

      // When iv.value is '1234', it becomes 1234 after being parsed by JSON.parse.
      // It needs to be corrected back to a string.
      if (typeof iv.value === 'number') {
        iv.value = String(iv.value);
      }

      if (Array.isArray(iv.value)) {
        const values: { src?: string }[] = [];
        for (let aImg of iv.value as typeof values) {
          if (aImg['src']) {
            aImg['src'] = `/api/images/value/${iv.project_id}/${aImg['src']}`;
          }
          values.push(aImg);
        }
        iv.value = values;
      }
      return iv;
    } catch (e) {
      console.error(e);
      return iv;
    }
  });
};

export function getPointsOfInterest(app: App, inputValues: GatherInputValue[]) {
  if (!app.allow_collection_on_poi) {
    return [];
  }

  const section = app.sections?.find(
    (section) => section.system_reference === 'point_of_interest'
  );
  if (!section) {
    return [];
  }

  const poiByNumber = {};
  for (let iv of inputValues) {
    if (iv.template_section_id !== section.id) {
      continue;
    }

    const field = section.template_fields?.find(
      (field) => field.id === iv.template_field_id
    );
    if (!field) {
      throw `The field was not found: field id is ${iv.template_field_id}.`;
    }

    poiByNumber[iv.template_section_index] =
      poiByNumber[iv.template_section_index] ?? {};
    if (field.system_reference === 'data_form') {
      poiByNumber[iv.template_section_index] = produce(
        poiByNumber[iv.template_section_index],
        (draft) => {
          draft.dataForm =
            typeof iv.value === 'string'
              ? parseInt(iv.value, 10)
              : typeof iv.value === 'number'
              ? iv.value
              : null;
        }
      );
    } else if (field.system_reference === 'longitude') {
      poiByNumber[iv.template_section_index] = produce(
        poiByNumber[iv.template_section_index],
        (draft) => {
          draft.longitude =
            typeof iv.value === 'string'
              ? parseFloat(iv.value)
              : typeof iv.value === 'number'
              ? iv.value
              : null;
        }
      );
    } else if (field.system_reference === 'latitude') {
      poiByNumber[iv.template_section_index] = produce(
        poiByNumber[iv.template_section_index],
        (draft) => {
          draft.latitude =
            typeof iv.value === 'string'
              ? parseFloat(iv.value)
              : typeof iv.value === 'number'
              ? iv.value
              : null;
        }
      );
    }
  }

  const result: any[] = [];
  const numbers = Object.keys(poiByNumber).map((n) => parseInt(n, 10));
  if (numbers.length > 0) {
    const maxNumber = Math.max(...numbers);
    for (let i = 0; i <= maxNumber; i++) {
      result.push(
        poiByNumber[i] ?? {
          dataForm: null,
          longitude: null,
          latitude: null,
        }
      );
    }
  }

  return result;
}

export function getCompositeKey(iv: InputValue): CompositeKey {
  return _pick(iv, [
    'sample_id',
    'template_tab_id',
    'template_section_id',
    'template_section_index',
    'template_field_id',
  ]);
}

export function findInputValueByCompositeKey(
  ivs: InputValue[],
  ck: CompositeKey
): InputValue | undefined {
  return ivs.find((iv) => _isEqual(getCompositeKey(iv), ck));
}
