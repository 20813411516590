<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { GatherField, InputValue, InputValuePlaceholder } from '../gather';
import { FieldTypeIds } from '../fields';
import FillPattern from '../components/FillPattern.vue';
import { getOptionExtension } from '../business-logic/lithology';

const props = defineProps<{
  secondaryField: GatherField;
  inputValue: InputValue | InputValuePlaceholder;
}>();

const lithologyOptionExtension = computed(() => {
  return getOptionExtension(props.secondaryField, props.inputValue.value);
});

const fillPatternSize = ref<[number, number]>([46, 46]);
const rootEl = ref<HTMLElement>();
onMounted(() => {
  const height = rootEl.value?.getBoundingClientRect().height;
  if (!height) {
    throw new Error(
      'Fill pattern size failed. The root element has no height.'
    );
  }
  fillPatternSize.value = [fillPatternSize.value[0], height];
});
</script>

<template>
  <div
    v-if="secondaryField.field_type_id === FieldTypeIds.LITHOLOGY"
    ref="rootEl"
    class="d-flex justify-content-between align-items-center"
  >
    <template v-if="inputValue">
      <div>
        <b>{{ inputValue.value }}</b>
      </div>
      <FillPattern
        class="flex-shrink-0"
        :value="lithologyOptionExtension.fillPattern"
        :color="lithologyOptionExtension.color"
        :size="fillPatternSize"
      />
    </template>
  </div>
  <div v-else>The secondary field is not supported.</div>
</template>
