<template>
  <div>
    <div class="row">
      <div
        class="form-group mb-2 col-12"
        :class="{
          'col-md-6': field.options.is_range,
        }"
      >
        <label class="form-label" for="startDepth">
          {{ field.options.is_range ? 'Start' : '' }} {{ field.label }}
          <sup v-if="field.is_required" class="text-danger">*</sup>
        </label>
        <input
          v-model="value"
          type="number"
          :class="{
            'border-danger': field.is_required && (!value || value == ''),
          }"
          :required="field.is_required"
          :step="field.options.range_interval"
          class="form-control"
          name="startDepth"
        />
      </div>

      <div
        v-if="field.options.is_range"
        class="form-group mb-2 col-12 col-md-6"
      >
        <label class="form-label" for="endDepth">End {{ field.label }} </label>
        <input
          v-model="value2"
          type="number"
          :class="{
            'border-danger': field.is_required && (!value || value == ''),
          }"
          :required="field.is_required"
          :step="field.options.range_interval"
          class="form-control"
          name="endDepth"
        />
      </div>
    </div>
    <small
      v-if="field.options.unit && field.options.unit != ''"
      class="d-block text-muted"
    >
      All depths are in the unit: <b>{{ field.options.unit }}</b
      >.
    </small>
  </div>
</template>

<script>
import { checkIsInputValueValueEmpty } from '../../business-logic/input-value';
import { normalizeDepth } from '../../business-logic/number';
import EventBus from '../../EventBus';

export default {
  props: {
    field: Object,
    inputValue: Object,
  },
  watch: {
    value: {
      handler(value) {
        if (!this.field.options.is_range) {
          return;
        }

        const rangeInterval = parseFloat(this.field.options.range_interval);
        if (
          !checkIsInputValueValueEmpty(value) &&
          !Number.isNaN(rangeInterval)
        ) {
          this.value2 = Number(value) + rangeInterval;
        }
      },
      immediate: true,
    },
  },
  computed: {
    value: {
      get() {
        if (!this.inputValue) {
          return '';
        }
        return normalizeDepth(this.inputValue.value);
      },
      set(value) {
        EventBus.$emit('updateInputValue', {
          inputValue: { ...this.inputValue, value },
          field: this.inputValue.template_field_id,
          sectionIndex: this.inputValue.template_section_index,
          templateTabId: this.inputValue.template_tab_id,
        });
      },
    },
    value2: {
      get() {
        if (!this.inputValue) {
          return '';
        }
        return normalizeDepth(this.inputValue.value2);
      },
      set(value) {
        EventBus.$emit('updateInputValue', {
          inputValue: { ...this.inputValue, value2: value },
          field: this.inputValue.template_field_id,
          sectionIndex: this.inputValue.template_section_index,
          templateTabId: this.inputValue.template_tab_id,
        });
      },
    },
  },
};
</script>
