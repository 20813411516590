<script setup lang="ts">
import AlertBox from '../components/AlertBox.vue';
import { useCollectionStore } from '../store/collection';
import { getPointsOfInterest } from '../business-logic/input-value';
import {
  getNumberTitle,
  getPrimaryFieldTitle as _getPrimaryFieldTitle,
} from '../business-logic/section';
import Spinner from '../components/Spinner.vue';
import { FieldTypeIds, checkIsConditionMet } from '../fields';
import FormInput from './FormInput.vue';
import SecondaryFieldHeader from './SecondaryFieldHeader.vue';
import EventBus from '../EventBus';
import {
  inject,
  ref,
  computed,
  watch,
  onBeforeUnmount,
  onMounted,
  nextTick,
} from 'vue';
import type {
  App,
  Section,
  GatherField,
  Item,
  InputValue,
  InputValuePlaceholder,
} from '../gather';

const props = withDefaults(
  defineProps<{
    search?: string | null;
    templateTab?: App;
    section: Section;
    allFields: GatherField[];
    allSections: Section[];
    sample?: Item | null;
    inputValues: InputValue[];
    showDelete?: boolean;
    showAddSection: boolean;
    collapse?: boolean;
    publicForm?: boolean;
    previewForm?: boolean;
    shouldEmit?: boolean;
    isNavigationVisible?: boolean;
    editingField?: any;
    keepAllOpen?: boolean;
    fields?: GatherField[];
    currentValue?: InputValue | null;
    sampleIdentifier?: string | null;
    cssFieldScrollMarginTop?: string;
  }>(),
  {
    search: null,
    publicForm: false,
    previewForm: false,
    shouldEmit: true,
    isNavigationVisible: false,
    cssFieldScrollMarginTop: '0px',
  }
);

const emit = defineEmits<{
  (event: 'fetchData', value: any): void;
  (event: 'clickCamera', value: any): void;
  (event: 'clickVideo', value: any): void;
  (event: 'clickSetPreview', value: any): void;
  (event: 'clickStartDrawing', value: any): void;
  (event: 'isLoading', value: boolean): void;
  (event: 'updateInputValues', value): void;
  (event: 'clearSearch', value: boolean): void;
  (event: 'input'): void;
}>();

const collectionStore = useCollectionStore();
const formContext: any = inject('formContext')!;

const sectionCount = ref(1);
const sectionToDelete = ref<number | null>(null);
const openedRepeatedSections = ref<number[]>([]);
// For some sections, e.g. GPS Point Metadata, data needs to be fetched and filled into the form.
const isFetchingByIndex = ref({});
const primaryFieldSectionTitles = ref<(string | number | null)[]>([]);
const reactivityNumber = ref(0); //DO WE NEED THIS IN VUE3? !NOTE!
const titleBars = ref<(HTMLDivElement | null)[]>([]);

const shownSectionFields = computed<GatherField[]>(() => {
  // from single input field pane
  if (props.fields) {
    return props.fields ?? [];
  }

  if (!props.publicForm || props.section.is_public_form) {
    return props.section.template_fields ?? [];
  }

  return (
    props.section.template_fields?.filter((f) => f.options?.is_public_form) ??
    []
  );
});

const fetchIcon = computed(() => {
  if (props.section.is_gps_point_metadata) {
    return 'fa-location';
  }

  return '';
});

const secondaryField = computed<GatherField | undefined>(() => {
  return props.section.template_fields?.find(
    (f) => f.id === props.section.secondary_field_id
  );
});

const isBespokePointOfInterestSection = computed(() => {
  return (
    props.templateTab?.allow_collection_on_poi &&
    props.section.system_reference === 'point_of_interest'
  );
});

const pointsOfInterest = computed(() => {
  return props.templateTab
    ? getPointsOfInterest(props.templateTab, props.inputValues)
    : [];
});

const pointsOfInterestWithData = computed(() => {
  return pointsOfInterest.value.filter((poi) => !!poi.dataForm);
});

const requiredFieldIdsBySectionIndex = computed(() => {
  return [...Array(sectionCount.value).keys()].reduce((accu, sectionIndex) => {
    accu[sectionIndex] = (props.section.template_fields ?? [])
      .filter(
        (f) =>
          f.is_required &&
          checkIsConditionMet(f, props.inputValues, sectionIndex)
      )
      .map((f) => f.id);
    return accu;
  }, {});
});

function getFieldId(fieldId, sectionIndex) {
  return `field-${fieldId}-${sectionIndex}`;
}

function getRepeatedSectionTitle(n) {
  return props.section.is_number_used_as_title
    ? getNumberTitle(n)
    : getPrimaryFieldTitle(n);
}

function emitFetchData(index) {
  emit('fetchData', {
    section: props.section,
    index,
    inputValues: props.inputValues,
    updateInputValue: ({ inputValue, field }) => {
      EventBus.$emit('updateInputValue', {
        inputValue,
        field,
        sectionIndex: index,
        templateTabId: props.section.template_tab_id,
      });
    },
    setIsFetching: (index, value) => {
      isFetchingByIndex.value = {
        ...isFetchingByIndex.value,
        [index]: value,
      };
    },
  });
}

function onAddSection(index) {
  emitFetchData(index);
}

function clickCamera(data) {
  if (!props.shouldEmit) {
    return;
  }
  emit('clickCamera', data);
}

function clickVideo(data) {
  if (!props.shouldEmit) {
    return;
  }
  emit('clickVideo', data);
}

function clickSetPreview(data) {
  if (!props.shouldEmit) {
    return;
  }
  emit('clickSetPreview', data);
}

function clickStartDrawing(data) {
  if (!props.shouldEmit) {
    return;
  }
  emit('clickStartDrawing', data);
}

function isLoading(value: boolean) {
  if (!props.shouldEmit) {
    return;
  }
  emit('isLoading', value);
}

function getInputValue(
  field,
  sectionIndex = 0
): InputValue | InputValuePlaceholder {
  if (sectionIndex < 0) {
    throw new Error('Cannot get negative sectionIndex');
  }
  const iv = props.inputValues.find(
    (iv) =>
      iv.template_field_id == field.id &&
      iv.template_section_index == sectionIndex
  );

  if (iv) {
    return iv;
  }

  const placeholder = {
    template_field_id: field.id,
    template_section_index: sectionIndex,
    template_section_id: field.template_section_id,
    value: null,
    value2: null,
    options: null,
    sample_id: props.sample?.id,
  };

  if (props.shouldEmit) {
    console.debug('Emitting updateInputValue', field.id, sectionIndex);
    EventBus.$emit('updateInputValue', {
      inputValue: placeholder,
      field,
      sectionIndex,
      templateTabId: props.section.template_tab_id,
      isDefaultInputValue: true,
    });
  }

  return placeholder;
}

function updateForm() {
  if (!props.section.is_repeatable) {
    sectionCount.value = 1;
    return;
  }

  for (let iv of props.inputValues) {
    if (
      iv.template_section_id == props.section.id &&
      iv.template_section_index > sectionCount.value - 1
    ) {
      sectionCount.value = iv.template_section_index + 1;
    }
  }
}

async function addSection(shouldDuplicate = false) {
  if (collectionStore.isBusy) {
    return;
  }

  const lastInputValues = props.inputValues.filter(
    (iv) =>
      (!props.sample || iv.sample_id === props.sample.id) &&
      iv.template_section_id === props.section.id &&
      iv.template_section_index === sectionCount.value - 1
  );
  const newInputValues: any[] = [];
  lastInputValues.forEach((liv) => {
    const field = props.allFields.find((f) => f.id === liv.template_field_id);
    const shouldDuplicateField =
      shouldDuplicate &&
      field?.field_type_id !== FieldTypeIds.MEDIA &&
      field?.field_type_id !== FieldTypeIds.DATE &&
      (field?.field_type_id !== FieldTypeIds.NUMBER ||
        !field?.options?.increment);
    const shouldRangeDepthKeepContinuity =
      field?.field_type_id === FieldTypeIds.DEPTH &&
      field.options?.is_range &&
      field.options.should_keep_continuity;

    if (!shouldDuplicateField && !shouldRangeDepthKeepContinuity) {
      return;
    }

    const { value, value2, template_section_index: templateSectionIndex } = liv;

    newInputValues.push({
      ...liv,
      id: null,
      template_section_index: templateSectionIndex + 1,
      value: shouldRangeDepthKeepContinuity ? value2 : value,
      value2: shouldRangeDepthKeepContinuity ? null : value2,
      deleted_at: null,
      created_at: null,
      updated_at: null,
    });
  });
  props.inputValues.push(...newInputValues);

  sectionCount.value++;
  if (openedRepeatedSections.value.indexOf(sectionCount.value) === -1) {
    openedRepeatedSections.value = [sectionCount.value];
  }

  await nextTick();

  const titleBar = titleBars.value[sectionCount.value - 1];
  if (titleBar) {
    titleBar.scrollIntoView();
  }
  if (props.isNavigationVisible) {
    const sampleModal = document.getElementById('sampleModal');
    if (sampleModal) {
      sampleModal.scrollTop -= 51;
    }
  }

  onAddSection(sectionCount.value - 1);
}

function removeSection(index) {
  const toDelete = props.inputValues.filter(
    (iv) =>
      iv.template_section_index == index &&
      iv.template_section_id == props.section.id
  );

  for (let inputValue of toDelete) {
    props.inputValues.splice(props.inputValues.indexOf(inputValue), 1);
  }

  props.inputValues.forEach((iv) => {
    if (
      iv.template_section_id == props.section.id &&
      iv.template_section_index > index &&
      iv.template_section_index > 0
    ) {
      iv.template_section_index--;
    }
  });

  // This is required because props.inputValues could be a
  // subset of the inputValues of a sample. For example,
  // when FormSection is used in the TableManagement.vue component.
  emit('updateInputValues', props.inputValues);

  sectionCount.value--;

  let rIndex = openedRepeatedSections.value.indexOf(index + 1);
  if (rIndex !== -1) {
    openedRepeatedSections.value.splice(rIndex, 1);
  }

  sectionToDelete.value = null;
}

function checkValidationStatus() {
  if (!props.section.template_fields) {
    return true;
  }

  const isValid =
    props.inputValues.filter(
      (v) =>
        !v.value &&
        !v.value2 &&
        v.template_section_id == props.section.id &&
        v.template_section_index >= 0 &&
        requiredFieldIdsBySectionIndex.value[v.template_section_index].includes(
          v.template_field_id
        )
    ).length === 0;

  props.section.validated = isValid;

  return isValid;
}

function canDeleteSection(sectionIndex) {
  const sectionValues = props.inputValues.filter(
    (iv) =>
      iv.template_section_id == props.section.id &&
      iv.template_section_index == sectionIndex
  );

  if (sectionValues.filter((iv) => iv.created_at).length == 0) {
    return true;
  }

  const currentDate = new Date().setHours(0, 0, 0, 0);

  return (
    sectionValues.filter(
      (iv) =>
        iv.created_at &&
        new Date(iv.created_at).setHours(0, 0, 0, 0) >= currentDate
    ).length > 0
  );
}

function toggleSectionCollapsed(option: any = null) {
  if (option != false) {
    emit('clearSearch', true);
  }
  if (option == null) {
    option = !props.section.collapsed;
  }
  props.section.collapsed = option;
}

function sectionRepeatIsCollapsed(index) {
  return openedRepeatedSections.value.indexOf(index) === -1;
}

function toggleSectionRepeatCollapsed(index) {
  emit('clearSearch', true);
  let rIndex = openedRepeatedSections.value.indexOf(index);
  if (rIndex !== -1) {
    openedRepeatedSections.value.splice(rIndex, 1);
    return;
  }

  openedRepeatedSections.value.push(index);
}

function collapseAllRepeatedSections() {
  emit('clearSearch', true);
  openedRepeatedSections.value = [];
}

function getPrimaryFieldTitle(index) {
  const { template_fields, primary_field_id } = props.section;
  const primaryField =
    (primary_field_id
      ? template_fields?.find((f) => f.id === primary_field_id) ?? null
      : null) ??
    template_fields?.find(
      (f) => f.field_type_id !== FieldTypeIds.COPY_DATA_LINK
    ) ??
    null;
  const inputValue = getInputValue(primaryField, index - 1);
  const value = _getPrimaryFieldTitle(
    primaryField,
    inputValue as any,
    index,
    (newValue) => {
      if (newValue !== primaryFieldSectionTitles.value[index]) {
        primaryFieldSectionTitles.value[index] = newValue;
        reactivityNumber.value++;
      }
    }
  );
  while (primaryFieldSectionTitles.value.length <= index) {
    primaryFieldSectionTitles.value.push(null);
  }
  primaryFieldSectionTitles.value[index] = value;
  return value;
}

function checkIsFetchButtonVisible(index) {
  if (props.section.is_gps_point_metadata) {
    const latitudeField = props.section.template_fields?.find(
      (f) => f.system_reference === 'latitude'
    );
    return !!props.inputValues.find(
      (iv) =>
        iv.template_section_id === props.section.id &&
        iv.template_section_index === (sectionCount.value === 1 ? 0 : index) &&
        iv.template_field_id === latitudeField?.id &&
        !iv.value
    );
  }

  return false;
}

function handleFetchClick(index) {
  emitFetchData(index);
}

function checkIsGapVisible(n) {
  return (
    n > 1 &&
    (!secondaryField.value ||
      secondaryField.value.field_type_id !== FieldTypeIds.LITHOLOGY)
  );
}

function highlightEditingField() {
  if (!props.editingField) {
    return;
  }

  nextTick(() => {
    const positionManager = document.querySelector('.gather-form');
    if (!positionManager) {
      return;
    }

    const fieldElement = document.getElementById(
      getFieldId(props.editingField.field_id, props.editingField.section_index)
    );
    if (!fieldElement) {
      return;
    }

    fieldElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  });
}

function checkIsTitleBarValid(
  titleBar: any
): titleBar is HTMLDivElement | null {
  return titleBar === null || titleBar instanceof HTMLDivElement;
}

function setTitleBar(index: number, titleBar: any) {
  if (!checkIsTitleBarValid(titleBar)) {
    throw new Error('A title bar must be a HTMLDivElement or null.');
  }

  titleBars.value[index] = titleBar;
}

watch(
  () => props.search,
  (updated) => {
    if (!!updated) {
      toggleSectionCollapsed(false);
      openedRepeatedSections.value = Array.from(
        { length: sectionCount.value },
        (_, i) => i + 1
      );
    }
  }
);

watch(
  () => props.section,
  () => {
    if (!props.section.is_repeatable) {
      sectionCount.value = 1;
      return;
    }
  }
);

watch(
  () => props.inputValues,
  function () {
    updateForm();
    checkValidationStatus();
  },
  {
    deep: true,
  }
);

watch(sectionCount, (newValue) => {
  formContext.updateSectionCount(
    props.section.template_tab_id,
    props.section.id,
    newValue
  );
});

watch(
  () => props.editingField,
  () => {
    highlightEditingField();
  }
);

onMounted(() => {
  toggleSectionCollapsed(false);

  const isNew =
    !props.previewForm &&
    (props.publicForm ||
      !props.sample?.id ||
      (sectionCount.value === 1 &&
        !props.inputValues.find(
          (iv) =>
            iv.id &&
            iv.sample_id === props.sample?.id &&
            iv.template_section_id === props.section.id &&
            iv.template_section_index === 0
        )));
  if (isNew) {
    onAddSection(0);
  }
});

onBeforeUnmount(() => {
  EventBus.$off('updateForm', updateForm);
});

updateForm();
checkValidationStatus();
EventBus.$on('updateForm', updateForm);

if (props.sample && props.sample.id && !props.keepAllOpen) {
  collapseAllRepeatedSections();

  if (
    props.collapse ||
    (props.section.validated &&
      props.inputValues.filter(
        (iv) =>
          (iv.value || iv.value2) && iv.template_section_id == props.section.id
      ).length > 0)
  ) {
    props.section.collapsed = true;
  }
}

if (props.keepAllOpen) {
  openedRepeatedSections.value = Array.from(
    { length: sectionCount.value },
    (_, i) => i + 1
  );
}

highlightEditingField();
</script>

<template>
  <div class="form-section">
    <div
      v-if="!currentValue"
      :id="'section-' + section.id"
      :class="[
        'bg-dark text-white d-flex justify-content-between align-items-center p-2 p-md-3 clickable rounded',
        {
          'mb-2': section.collapsed,
        },
      ]"
      @click.prevent="toggleSectionCollapsed()"
    >
      <span class="mb-0 fs-md-6 fw-medium">{{ section.label }}</span>

      <div class="d-flex align-items-center">
        <button
          v-if="sectionCount === 1 && checkIsFetchButtonVisible(0)"
          type="button"
          class="btn btn-outline-secondary text-white me-2"
          :disabled="collectionStore.isBusy || isFetchingByIndex[0]"
          title="Click to get the current position."
          @click.stop="handleFetchClick(0)"
        >
          <Spinner v-if="isFetchingByIndex[0]" small />
          <i v-else class="fas" :class="fetchIcon" />
        </button>

        <span
          :class="[
            'fal mb-0',
            {
              'fa-chevron-down': section.collapsed,
              'fa-chevron-up': !section.collapsed,
            },
          ]"
        />
      </div>
    </div>

    <div v-if="!section.collapsed">
      <template v-if="!isBespokePointOfInterestSection">
        <div class="py-2">
          <div
            v-if="shownSectionFields.length == 0"
            class="alert alert-danger mb-0"
          >
            No fields exist under this section, please add them in the template
            builder.
          </div>

          <template v-else>
            <div
              v-for="n in sectionCount"
              :id="`section-${section.id}-${n}`"
              :key="n"
              :class="[
                'position-relative',
                {
                  'mt-2': !currentValue && checkIsGapVisible(n),
                },
              ]"
            >
              <div
                v-if="sectionCount > 1 && !currentValue"
                :ref="(titleBar) => setTitleBar(n - 1, titleBar)"
                class="d-flex justify-content-between align-items-center bg-light clickable title-bar mb-1 w-100"
                @click="toggleSectionRepeatCollapsed(n)"
              >
                <div
                  class="p-2 px-3 overflow-hidden text-truncate"
                  :title="String(getRepeatedSectionTitle(n))"
                >
                  <span class="fw-medium">{{
                    getRepeatedSectionTitle(n)
                  }}</span>
                </div>

                <SecondaryFieldHeader
                  v-if="secondaryField"
                  class="flex-grow-1 flex-shrink-0 align-self-stretch"
                  :secondaryField="secondaryField"
                  :inputValue="getInputValue(secondaryField, n - 1)"
                />

                <div
                  class="flex-shrink-0 d-flex justify-content-end align-items-center p-2 px-3 action-bar"
                >
                  <button
                    v-if="checkIsFetchButtonVisible(n - 1)"
                    type="button"
                    class="btn btn-sm btn-outline-secondary me-2"
                    title="Click to get the current position."
                    :disabled="
                      collectionStore.isBusy || isFetchingByIndex[n - 1]
                    "
                    @click.stop="handleFetchClick(n - 1)"
                  >
                    <Spinner v-if="isFetchingByIndex[n - 1]" small />
                    <i v-else class="fas" :class="fetchIcon" />
                  </button>

                  <button
                    v-if="sectionToDelete != n && canDeleteSection(n - 1)"
                    class="btn btn-sm btn-outline-danger"
                    :disabled="
                      collectionStore.isBusy || isFetchingByIndex[n - 1]
                    "
                    @click.stop="() => (sectionToDelete = n)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <div
                    v-if="sectionToDelete == n"
                    class="btn-group btn-group-sm"
                  >
                    <button
                      type="button"
                      class="btn btn-light"
                      :disabled="
                        collectionStore.isBusy || isFetchingByIndex[n - 1]
                      "
                      @click.stop="() => (sectionToDelete = null)"
                    >
                      <i class="fas fa-times fa-fw" />
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      :disabled="
                        collectionStore.isBusy || isFetchingByIndex[n - 1]
                      "
                      @click.stop="removeSection(n - 1)"
                    >
                      <i class="fas fa-check fa-fw" />
                    </button>
                  </div>

                  <h6
                    :class="[
                      'fal clickable mb-0 ms-2',
                      {
                        'fa-chevron-down': sectionRepeatIsCollapsed(n),
                        'fa-chevron-up': !sectionRepeatIsCollapsed(n),
                      },
                    ]"
                    @click.stop="toggleSectionRepeatCollapsed(n)"
                  />
                </div>
              </div>

              <div v-if="sectionCount === 1 || !sectionRepeatIsCollapsed(n)">
                <FormInput
                  v-for="(field, fieldIndex) of shownSectionFields"
                  v-show="
                    !currentValue ||
                    (currentValue.template_field_id === field.id &&
                      currentValue.template_section_index === n - 1 &&
                      section.id === currentValue.template_section_id)
                  "
                  :id="getFieldId(field.id, n)"
                  :key="`field-${field.id}`"
                  :editingByValue="currentValue"
                  :search="search"
                  :app="templateTab"
                  :section="section"
                  :field="field"
                  :sample="sample"
                  :inputValue="getInputValue(field, n - 1)"
                  :lastValue="n > 1 ? getInputValue(field, n - 2) : null"
                  :inputValues="inputValues"
                  :fields="shownSectionFields"
                  :allFields="allFields"
                  :allSections="allSections"
                  :sectionCount="sectionCount"
                  :sectionIndex="n - 1"
                  :templateTabId="section.template_tab_id"
                  :repeatable="!!section.is_repeatable"
                  :sampleIdentifier="sampleIdentifier"
                  :class="{
                    'mb-2': fieldIndex != shownSectionFields.length - 1,
                  }"
                  :cssFieldScrollMarginTop="cssFieldScrollMarginTop"
                  @input="emit('input')"
                  @clickCamera="clickCamera"
                  @clickVideo="clickVideo"
                  @clickSetPreview="clickSetPreview"
                  @clickStartDrawing="clickStartDrawing"
                  @isLoading="isLoading"
                />
              </div>
            </div>
          </template>
        </div>

        <template
          v-if="
            showAddSection &&
            shownSectionFields.length > 0 &&
            section.is_repeatable
          "
        >
          <div class="d-flex flex-wrap gap-md-2 gap-1">
            <div class="flex-grow-1 text-center mb-2">
              <a
                :class="[
                  'btn btn-outline-primary w-100',
                  { disabled: collectionStore.isBusy },
                ]"
                @click="() => addSection()"
              >
                <i class="fas fa-plus"></i>
                Repeat {{ section.label }}
              </a>
            </div>

            <div class="flex-grow-1 text-center">
              <a
                :class="[
                  'btn btn-outline-primary w-100',
                  { disabled: collectionStore.isBusy },
                ]"
                @click="() => addSection(true)"
              >
                <i class="fas fa-copy"></i>
                Duplicate the previous one
              </a>
            </div>
          </div>
        </template>
      </template>
      <div v-else>
        <AlertBox type="info" class="mt-2">
          There are {{ pointsOfInterest.length }} data points,
          {{ pointsOfInterestWithData.length }} of which
          {{ pointsOfInterestWithData.length === 1 ? 'has' : 'have' }} data
          collected.
        </AlertBox>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-section {
  .title-bar {
    min-height: 46px;

    .action-bar {
      min-width: 90px;
    }
  }
}
</style>
